import { Box, Button, Grid, Typography } from "@mui/material";
import { ChangeEvent, useState, useRef } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { StandardTestForm } from "../../utils/interface/order";

declare module "react" {
  interface InputHTMLAttributes<T> extends HTMLAttributes<T> {
    webkitdirectory?: boolean;
  }
}

/**
 * @fileoverview This file contains the UploadFile Component for uploading of /Histology files in the OrderTest page
 */

//type FileUploadCategory = "" | "Histology"
type FileUploadCategory = "Histology";
/*** UploadFile Props Type DeclarationAAAAAAA_ **/
interface UploadFileProps {
  // Category of the file to be uploaded.
  setUploadedFiles: (status: boolean) => void;
  category: FileUploadCategory;
}

/**
 * UploadFile Component.
 * Displays button for uploading of /Histology files. Shows the names of the files uploaded.
 *
 * */
const UploadFile = (props: UploadFileProps) => {
  const { control, setValue } = useFormContext<StandardTestForm>();
  const [fileNames, setFileNames] = useState<string[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  /** Handles Logic for uploading of /Histology files in the OrderTest page. **/
  const updateFilesHandler = (e: ChangeEvent<HTMLInputElement>) => {
    // setFileNames([]);
    setErrorMessage(null);
    if (!e.target.files) return;
    const acceptedExtensions = [".tif", ".tiff", ".svs", ".dat"];
    const accpetedSize = 5 * 1024 * 1024 * 1024; // 5GB
    let inCorrectFileCheck = false;
    let totalSize = 0;
    let contains1MapFile = false;
    const folderFiles = Array.from(e.target.files);
    setFileNames([]);
    setValue("orderFilesHistology", undefined);
    if (folderFiles.length > 1) {
      // Folder uploaded
      let ifFound = false;
      folderFiles.forEach((file) => {
        const newFileName = file.name;
        const fileExtension = newFileName.slice(newFileName.lastIndexOf("."));
        // Check If there is 1_Map
        if (newFileName === "1_Map") {
          contains1MapFile = true;
        }
        totalSize += file.size;
        if (acceptedExtensions.includes(fileExtension.toString())) {
          //setFileNames((prevFileNames) => [...prevFileNames, newFileName]);
          ifFound = true;
        } else {
          if (!ifFound) {
            setFileNames([]);
            setValue("orderFilesHistology", undefined);
            setErrorMessage(
              "Only TIFF and SVS file-types are currently supported. Please convert/export your file to one of those before upload. If you need help with this, please contact support@ataraxis.ai."
            );
            inCorrectFileCheck = true;
          }
        }
      });
      if (contains1MapFile) {
        if (totalSize > accpetedSize) {
          const providedFileSize = totalSize / (1024 * 1024 * 1024);
          setFileNames([]);
          setValue("orderFilesHistology", undefined);
          setErrorMessage(`Maximum file size exceeded (5GB), provided folder size was ${providedFileSize}GB.`);
          inCorrectFileCheck = true;
        }
      } else {
        setErrorMessage(
          "DICOM folder upload detected, but not 1_Map file found in directory. Please check that the folder contains all necessary files. Contact support@ataraxis.ai if you continue having trouble."
        );
        setValue("orderFilesHistology", undefined);
        inCorrectFileCheck = true;
      }
    } else {
      // Single File uploaded check
      if (folderFiles.length === 1) {
        const newFileName = folderFiles[0].name;
        const fileExtensionSlice = newFileName.slice(newFileName.lastIndexOf("."), newFileName.length);
        if (acceptedExtensions.includes(fileExtensionSlice.toString())) {
          if (folderFiles[0].size > accpetedSize) {
            const providedFileSize = folderFiles[0].size / (1024 * 1024 * 1024);
            setFileNames([]);
            setValue("orderFilesHistology", undefined);
            if (fileInputRef.current) {
              fileInputRef.current.value = "";
            }
            setErrorMessage(`Maximum file size exceeded (5GB), provided file was ${providedFileSize}GB.`);
            props.setUploadedFiles(false);
            inCorrectFileCheck = true;
            return inCorrectFileCheck;
          } else {
            setFileNames((_fileNames: string[]) => [...fileNames, newFileName]);
            setValue("orderFilesHistology", e.target.files);
            props.setUploadedFiles(true);
            inCorrectFileCheck = false;
          }
        } else {
          setFileNames([]);
          setValue("orderFilesHistology", undefined);
          if (fileInputRef.current) {
            fileInputRef.current.value = "";
          }
          setErrorMessage(
            "Only TIFF and SVS file-types are currently supported. Please convert/export your file to one of those before upload. If you need help with this, please contact support@ataraxis.ai."
          );
          props.setUploadedFiles(false);
          inCorrectFileCheck = true;
        }
      }
      return inCorrectFileCheck;
    }
  };
  // Handle the delete button click
  const handleDelete = () => {
    setFileNames([]);
    setValue("orderFilesHistology", undefined);
    setErrorMessage(null);
    props.setUploadedFiles(false);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };
  //const title = props.category === "" ? " File Upload" : "Histology File Upload";
  //const controllerName = props.category === "" ? "orderFiles" : "orderFilesHistology";
  const title = "Whole Slide Image Upload";
  const controllerName = "orderFilesHistology";

  return (
    <Box sx={{ maxWidth: "100%", overflow: "auto" }}>
      <Typography variant="h6" sx={{ mt: 2 }}>
        {title}
      </Typography>
      <Grid container className="report-form-input" direction="column">
        <Grid item>
          <Controller
            name={controllerName}
            control={control}
            render={({ field: { ref, name, onChange } }) => (
              <>
                <input
                  hidden
                  ref={(el) => {
                    ref(el);
                    fileInputRef.current = el;
                  }}
                  name={name}
                  type="file"
                  multiple
                  // accept=".tif, .tiff, .svs"
                  id={"button-file" + props.category}
                  onChange={(e) => {
                    const inCorrectFileCheck = updateFilesHandler(e);
                    onChange(e.target.files);
                  }}
                />
                <label htmlFor={"button-file" + props.category}>
                  <Button variant="outlined" component="span" sx={{ mb: 2 }}>
                    Upload Files
                  </Button>
                </label>
              </>
            )}
          />
        </Grid>
        <Grid item>
          <Box className="text-truncate text-muted">
            {fileNames.map((filename) => (
              <Typography key={filename} variant="body2">
                {filename}
              </Typography>
            ))}
          </Box>
        </Grid>
        {errorMessage && (
          <Grid item>
            <Typography variant="body2" color="error" sx={{ mt: 2 }}>
              {errorMessage}
            </Typography>
          </Grid>
        )}
        <Grid item>
          <Button variant="outlined" color="secondary" onClick={handleDelete} sx={{ mt: 2 }}>
            Cancel
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UploadFile;
