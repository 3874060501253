import * as React from "react";
import CancerFoci from "@components/orderTest/CancerFoci";
import ClinicalStaging from "@components/orderTest/ClinicalStaging";
import MenopausalStatus from "@components/orderTest/MenopausalStatus";
import Demographics from "@components/orderTest/Demographics";
import UploadFile from "@components/orderTest/UploadFile";
import { LinearProgressWithLabel } from "@components/progressBar/LinearProgressWithLabel";
import axios from "axios";
import { useCurrentUserData } from "@hooks/user";
import Send from "@mui/icons-material/Send";
import { Box, Button, CircularProgress, Grid, Typography, Modal } from "@mui/material";
import LinearProgress, { LinearProgressProps } from "@mui/material/LinearProgress";
import { green } from "@mui/material/colors";
import { IPatientAndOrderId, StandardTestForm } from "@utils/interface/order";
import { useState, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import RealWorldExamples from "@components/orderTest/orderTest3/RealWorldExamples";
import WithOrderFormLayOut from "@hoc/withOrderFormLayOut";
import { addDigitalTestOrder, generateReport } from "@services/orderService";
import { uploadHistologyToStorage, uploadHistologyToStorageInf } from "@storage/dicom";
import { useAuth } from "@store/auth/model";
import { AuthUserNotFoundException } from "@repository/auth";
import { AtaraxisUser, getOrganization, getOrgName, getUser, UserNotFoundException } from "@repository/user";
import "../Report.scss";

const infRoot = "inference";
const infPatients = "patients";

function LinearProgressWithLabelModal(props: LinearProgressProps & { value: number }) {
  //const { value, ...otherProps } = props;
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

const StandardTest = () => {
  const [progresses, setProgresses] = useState<number[]>([]);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const user = useCurrentUserData();
  const [progress, setProgress] = useState(0);
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [orgName, setOrgName] = useState<string | null>(null);
  const [uploadedFiles, setUploadedFiles] = useState(false);

  const methods = useForm<StandardTestForm>({
    defaultValues: {
      patient: {
        staging: {
          tnmCategoryT: null,
          tnmCategoryN: null
        },
        foci: {
          focusSize: null,
          positiveNodesNum: null,
          estrogen: "",
          progesterone: "",
          her2: "",
          ki67: 0,
          histology: { grade: "", subtypes: [] }
        }
      }
    }
  });

  console.log(methods.watch());

  const navigate = useNavigate();
  const auth = useAuth();
  if (!auth) throw new AuthUserNotFoundException();

  useEffect(() => {
    if (!auth) throw new AuthUserNotFoundException();
    const fetchOrganization = async () => {
      try {
        const currentUser = await getUser(auth.uid);
        if (!currentUser) throw new UserNotFoundException();
        setFirstName(currentUser.firstname);
        setLastName(currentUser.lastname);
        const currentUserOrg = await getOrgName(currentUser.organization);
        if (!currentUserOrg) throw new UserNotFoundException();
        setOrgName(currentUserOrg);
      } catch (err) {
        console.error("Error fetching ordered tests:", err);
      }
    };

    fetchOrganization();
  }, [auth, auth.uid]);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    height: 350,
    bgcolor: "background.paper",
    border: "2px solid #4034b4",
    boxShadow: 24,
    p: 4
  };
  const onSubmit = async (orderFormData: StandardTestForm) => {
    console.log(orderFormData.orderFilesHistology);
    setLoading(true);
    setSubmitting(true);
    setProgress(0);
    if (!auth) {
      alert("Current user not found");
      setLoading(false);
      setSubmitting(false);
      return;
    }
    const physician = user.lastname + ", " + user.firstname;
    if (!uploadedFiles) {
      const ans = confirm(
        "You have not uploaded digital pathology imaging. If you submit the order without this file, the results might not be accurate. Are you sure you want to proceed?"
      );
      if (!ans) {
        setLoading(false);
        setSubmitting(false);
        return;
      }
    }
    setProgress(0);
    /*
    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev < 90) return prev + 10;
        return prev;
      });
    }, 300);
    */
    /*
    const updateProgress = (target: number) => {
      const interval = setInterval(() => {
        setProgress((prev) => {
          if (prev < target) {
            return Math.min(prev + 10, target);
          } else {
            clearInterval(interval);
            return prev;
          }
        });
      }, 100);
    };/
    */
    //const folderId = await uploadHistologyToStorage(auth, orderFormData, setProgresses);
    //setProgresses([]);
    setProgress(50);
    //setSubmitting(true);
    //const numberOfMRIFiles = orderFormData.orderFilesMri ? orderFormData.orderFilesMri.length : 0;
    const numberOfHistologyFiles = orderFormData.orderFilesHistology ? orderFormData.orderFilesHistology.length : 0;
    const [orderId, patientId] = await addDigitalTestOrder(
      orderFormData,
      auth,
      physician,
      //numberOfMRIFiles,
      numberOfHistologyFiles
    );
    console.log(orderId);
    setProgress(75);
    const slidePath = await uploadHistologyToStorageInf(
      auth,
      orderFormData,
      setProgresses,
      patientId,
      firstName,
      lastName
    );

    const slideFullPath = `${slidePath}`;
    console.log(slideFullPath);
    setProgress(75);
    axios.defaults.timeout = 18000000;
    axios
      .post(
        `https://ataraxis-ai--inference-modal-connection-inference-on-patient.modal.run?patient_id=${encodeURIComponent(
          patientId
        )}&order_id=${encodeURIComponent(orderId)}&slide_path=${encodeURIComponent(slideFullPath)}`
      )
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        if (error.response) {
          // The server responded with a status code outside the 2xx range
          console.log("Error response:", error.response);
        } else if (error.request) {
          // The request was made but no response was received
          console.log("Error request:", error.request);
        } else {
          // Something happened in setting up the request that triggered an error
          console.log("Error message:", error.message);
        }
      });
    setProgress(100);

    const state: IPatientAndOrderId = {
      orderId: orderId,
      patientId: patientId
    };

    navigate("/order-record", { state });
  };
  const { setValue, handleSubmit } = methods;

  return (
    <>
      <Grid container id="fill-test-form" direction="column" alignItems="center">
        <Grid item>
          <Typography sx={{ fontWeight: 100, mb: 2, fontSize: "48px", textAlign: "center" }}>
            Requisition Form
          </Typography>
        </Grid>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Demographics />
            <ClinicalStaging />
            <MenopausalStatus />
            <CancerFoci methods={methods} />
            <UploadFile setUploadedFiles={setUploadedFiles} category={"Histology"} />
            <Typography variant="h6" sx={{ fontWeight: 100, mb: 2, mt: 2 }}>
              Ataraxis Breast is intended for Research Use Only. Not for use in diagnostic procedures.
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box sx={{ m: 1, position: "relative" }}>
                <Button
                  id="test-order-submit"
                  type="submit"
                  size="large"
                  value="Generate Report"
                  variant="contained"
                  color="secondary"
                  disabled={loading}
                  endIcon={<Send />}
                >
                  Submit test order
                </Button>
                {loading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: green[500],
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px"
                    }}
                  />
                )}
              </Box>
              <Modal open={submitting} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                  <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ color: "#4034b4" }}>
                    Generating Report...
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Submitting data and connecting models for report generation, do not exit this page!
                  </Typography>
                  {progresses.length !== 0 && (
                    <Box className="mt-1" sx={{ flexGrow: 1 }}>
                      <Typography variant="h6" color="text.secondary">
                        Uploading images...
                      </Typography>
                      <LinearProgressWithLabel value={progresses.reduce((a, b) => a + b, 0) * 100} />
                    </Box>
                  )}
                </Box>
              </Modal>
            </Box>
          </form>
        </FormProvider>
      </Grid>
      {orgName === "Ataraxis" && (
        <Grid item lg={6}>
          <RealWorldExamples setValue={setValue} />
        </Grid>
      )}
    </>
  );
};
export default WithOrderFormLayOut(StandardTest);
